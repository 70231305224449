// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../../../models/User.res.js";
import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as RailsContext from "../../../../models/RailsContext.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectsViewContext from "../../../../models/ProjectsViewContext.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as ProjectStickyBarData from "../_components/ProjectStickyBar/ProjectStickyBarData.res.js";
import * as NetworkPrivateLineProject from "../../../../models/NetworkPrivateLineProject.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as UserNetworkPrivateLineProject from "./UserNetworkPrivateLineProject.res.js";

function UserNetworkPrivateLineProject__JsBridge$default(props) {
  var estimatedDates = props.estimatedDates;
  var railsContext = props.railsContext;
  var projectStickyBar = props.projectStickyBar;
  var viewContext = props.viewContext;
  var concierges = props.concierges;
  var project = props.project;
  var railsContext$1 = React.useMemo((function () {
          return RailsContext.fromJson(railsContext);
        }), [railsContext]);
  var project$1 = React.useMemo((function () {
          return NetworkPrivateLineProject.fromJson(project);
        }), [project]);
  var viewContext$1 = React.useMemo((function () {
          return ProjectsViewContext.fromJson(viewContext);
        }), [viewContext]);
  var projectStickyBarData = React.useMemo((function () {
          return ProjectStickyBarData.fromJson(projectStickyBar);
        }), [projectStickyBar]);
  var concierges$1 = React.useMemo((function () {
          return Belt_Array.map(concierges, User.fromJson);
        }), [concierges]);
  var estimatedDates$1 = React.useMemo((function () {
          return Project.EstimatedDates.fromJson(estimatedDates);
        }), [estimatedDates]);
  var canSendProposals = Belt_Result.getExn(Json$JsonCombinators.decode(props.canSendProposals, Json_Decode$JsonCombinators.bool));
  return JsxRuntime.jsx(UserNetworkPrivateLineProject.make, {
              project: project$1,
              concierges: concierges$1,
              canSendProposals: canSendProposals,
              viewContext: viewContext$1,
              userRole: railsContext$1.userRole,
              projectStickyBarData: projectStickyBarData,
              desktop: railsContext$1.desktop,
              tablet: railsContext$1.tablet,
              mobile: railsContext$1.mobile,
              estimatedDates: estimatedDates$1
            });
}

var $$default = UserNetworkPrivateLineProject__JsBridge$default;

export {
  $$default as default,
}
/* User Not a pure module */
