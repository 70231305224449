// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsPanel from "../_components/UserProjectDetailsPanel/UserProjectDetailsPanel.res.js";
import * as ProjectRequiredProducts_CheckmarkableOptions from "../_components/ProjectRequiredProducts/ProjectRequiredProducts_CheckmarkableOptions.res.js";
import * as UserNetworkPrivateLineProject_Details_Location from "./UserNetworkPrivateLineProject_Details_Location.res.js";
import * as UserNetworkPrivateLineProject_Details_LocationZ from "./UserNetworkPrivateLineProject_Details_LocationZ.res.js";
import * as UserNetworkPrivateLineProject_Details_RequiredProductsScss from "./UserNetworkPrivateLineProject_Details_RequiredProducts.scss";
import * as UserNetworkPrivateLineProject_Details_RequiredProducts_ProductServiceDescription from "./UserNetworkPrivateLineProject_Details_RequiredProducts_ProductServiceDescription.res.js";

var css = UserNetworkPrivateLineProject_Details_RequiredProductsScss;

function panelHeading(panelHeading$1) {
  return JsxRuntime.jsx("h4", {
              children: panelHeading$1,
              className: css.panelHeading
            });
}

function UserNetworkPrivateLineProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions(props) {
  var options = props.options;
  if (options !== undefined && options.length !== 0) {
    return JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx(ProjectRequiredProducts_CheckmarkableOptions.make, {
                      options: options,
                      cssStart: "SwitchLight"
                    }),
                className: css.propertiesContainer
              });
  } else {
    return null;
  }
}

var CheckmarkableOptions = {
  make: UserNetworkPrivateLineProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions
};

function UserNetworkPrivateLineProject_Details_RequiredProducts_ProductPanel(props) {
  var __isExpanded = props.isExpanded;
  var networkPrivateLineConfiguration = props.networkPrivateLineConfiguration;
  var isExpanded = __isExpanded !== undefined ? __isExpanded : false;
  var locations = networkPrivateLineConfiguration.locationZs;
  return JsxRuntime.jsx(UserProjectDetailsPanel.make, {
              className: css.panel,
              heading: props.heading,
              isExpanded: isExpanded,
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                          children: [
                            JsxRuntime.jsx(UserNetworkPrivateLineProject_Details_Location.make, {
                                  networkPrivateLineConfiguration: networkPrivateLineConfiguration
                                }),
                            JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(UserNetworkPrivateLineProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                label: "Access Type",
                                                value: networkPrivateLineConfiguration.accessType
                                              }),
                                          className: Cx.cx([
                                                css.propertyLeft,
                                                css.stayShaded
                                              ])
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(UserNetworkPrivateLineProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                label: "Port Speed",
                                                value: networkPrivateLineConfiguration.portSpeed
                                              }),
                                          className: Cx.cx([
                                                css.propertyRight,
                                                css.switchShaded
                                              ])
                                        }),
                                    JsxRuntime.jsx(UserNetworkPrivateLineProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions, {
                                          options: networkPrivateLineConfiguration.privateLineFeatures
                                        }),
                                    locations !== undefined ? Belt_Array.mapWithIndex(locations, (function (index, $$location) {
                                              return JsxRuntime.jsx(UserNetworkPrivateLineProject_Details_LocationZ.make, {
                                                          location: $$location,
                                                          index: index
                                                        }, String(index));
                                            })) : null
                                  ],
                                  className: css.propertiesContainer
                                })
                          ]
                        }),
                    className: css.panelBody
                  })
            });
}

var make = UserNetworkPrivateLineProject_Details_RequiredProducts_ProductPanel;

export {
  css ,
  panelHeading ,
  CheckmarkableOptions ,
  make ,
}
/* css Not a pure module */
