// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../libs/Date.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as NetworkPrivateLineLocationZ from "./NetworkPrivateLineLocationZ.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              startDate: field.required("startDate", $$Date.Naive.decoder),
              contractLength: field.required("contractLength", Json_Decode$JsonCombinators.$$int),
              streetAddress: field.required("streetAddress", Json_Decode$JsonCombinators.string),
              buildingOrFloor: field.required("buildingOrFloor", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              suite: field.required("suite", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              cityOrTown: field.required("cityOrTown", Json_Decode$JsonCombinators.string),
              subLocality: field.required("subLocality", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              stateOrProvince: field.required("stateOrProvince", Json_Decode$JsonCombinators.string),
              country: field.required("country", Json_Decode$JsonCombinators.string),
              postalCode: field.required("postalCode", Json_Decode$JsonCombinators.string),
              prefix: field.required("prefix", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              additionalLocationDetails: field.required("additionalLocationDetails", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              accessType: field.required("accessType", Json_Decode$JsonCombinators.string),
              portSpeed: field.required("portSpeed", Json_Decode$JsonCombinators.string),
              privateLineFeatures: field.required("privateLineFeatures", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
              locationZs: field.required("locationZs", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.array(NetworkPrivateLineLocationZ.decoder)))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
