// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectRequiredProducts_CheckmarkableOptions from "../_components/ProjectRequiredProducts/ProjectRequiredProducts_CheckmarkableOptions.res.js";
import * as UserNetworkPrivateLineProject_Details_RequiredProductsScss from "./UserNetworkPrivateLineProject_Details_RequiredProducts.scss";
import * as UserNetworkPrivateLineProject_Details_RequiredProducts_ProductServiceDescription from "./UserNetworkPrivateLineProject_Details_RequiredProducts_ProductServiceDescription.res.js";

var css = UserNetworkPrivateLineProject_Details_RequiredProductsScss;

function UserNetworkPrivateLineProject_Details_LocationZ$CheckmarkableOptions(props) {
  var options = props.options;
  if (options !== undefined && options.length !== 0) {
    return JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(ProjectRequiredProducts_CheckmarkableOptions.make, {
                            options: options,
                            cssStart: "SwitchLight"
                          }),
                      className: css.checkboxes
                    }),
                className: css.propertiesContainer
              });
  } else {
    return null;
  }
}

var CheckmarkableOptions = {
  make: UserNetworkPrivateLineProject_Details_LocationZ$CheckmarkableOptions
};

function UserNetworkPrivateLineProject_Details_LocationZ(props) {
  var $$location = props.location;
  var addressLabel = function ($$location) {
    var __x = Belt_Array.keep([
          Belt_Option.getWithDefault($$location.buildingOrFloor, ""),
          $$location.streetAddress,
          Belt_Option.getWithDefault($$location.suite, ""),
          $$location.cityOrTown,
          $$location.stateOrProvince,
          $$location.postalCode,
          $$location.country
        ], (function (item) {
            return item !== "";
          }));
    return Js_array.joinWith(", ", __x);
  };
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("p", {
                      children: "#" + (String(props.index + 1 | 0) + (" Location (Z) in " + ($$location.cityOrTown + (", " + ($$location.stateOrProvince + (", " + $$location.country)))))),
                      className: css.productName
                    }),
                JsxRuntime.jsx("p", {
                      children: addressLabel($$location),
                      className: css.locationText
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserNetworkPrivateLineProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Access Type",
                                    value: $$location.accessType
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.stayShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserNetworkPrivateLineProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Port Speed",
                                    value: $$location.portSpeed
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.switchShaded
                                  ])
                            }),
                        JsxRuntime.jsx(UserNetworkPrivateLineProject_Details_LocationZ$CheckmarkableOptions, {
                              options: $$location.privateLineFeatures
                            })
                      ],
                      className: css.propertiesContainer
                    })
              ],
              className: css.locationZContainer
            });
}

var make = UserNetworkPrivateLineProject_Details_LocationZ;

export {
  css ,
  CheckmarkableOptions ,
  make ,
}
/* css Not a pure module */
