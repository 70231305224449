import * as React from 'react';

import UserNetworkPrivateLineProject from './UserNetworkPrivateLineProject__JsBridge.res.js';

export default ({ context, ...props }) => (
  <UserNetworkPrivateLineProject
    project={props.project}
    concierges={props.concierges}
    canSendProposals={props.canSendProposals}
    viewContext={props.viewContext}
    projectStickyBar={props.projectStickyBar}
    estimatedDates={props.estimatedDates}
    railsContext={context}
  />
);
