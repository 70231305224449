// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserNetworkPrivateLineProject_Details_RequiredProductsScss from "./UserNetworkPrivateLineProject_Details_RequiredProducts.scss";

var css = UserNetworkPrivateLineProject_Details_RequiredProductsScss;

function UserNetworkPrivateLineProject_Details_RequiredProducts_ProductServiceDescription(props) {
  var label = props.label;
  return JsxRuntime.jsxs("div", {
              children: [
                label !== undefined ? JsxRuntime.jsx("p", {
                        children: label,
                        className: Cx.cx([
                              css.productServiceLabel,
                              css.withColon
                            ])
                      }) : null,
                JsxRuntime.jsx("p", {
                      children: props.value,
                      className: css.productServiceValue
                    })
              ],
              className: css.productsServiceWrapper
            });
}

var make = UserNetworkPrivateLineProject_Details_RequiredProducts_ProductServiceDescription;

export {
  css ,
  make ,
}
/* css Not a pure module */
